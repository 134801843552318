const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://servikar.app' : 'http://192.168.100.106:3036',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://servikar.app' : 'http://192.168.100.106:3036',
    api: 'api/',
    apiSocket: 'servikar::1.2',
    nameDir: 'servikar',
    package: 'app.servikar.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDHACedwp2TjVqXit2yQuX2G_YW_x0TPvs',
    appName: 'ServiKar',
    provider: '',
    colorPrimary: '#D41C19',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.servikar.passenger',
    playStoreDriverId: 'app.servikar.driver',
    appStorePassengerId: '6501950149',
    appStoreDriverId: '6501950230',
    email: "servicarnaranjal@gmail.com",
};
export default config;
